export const environment = {
  production: false,
  msalConfig: {
    auth: {
      clientId: '240069ed-a8b4-43ca-b7d0-082242e88e75',
      authority:
        'https://login.microsoftonline.com/99c75c69-9ef2-4c4e-b5e3-54b6cf95c977',
    },
  },
  apiConfig: {
    scopes: ['https://analysis.windows.net/powerbi/api/.default'],
    uri: 'https://api.powerbi.com/v1.0/myorg',
  },
  customerAPI: {
    uri: 'https://func-healthguard-dev-01.azurewebsites.net',
    scopes: ['api://15232481-f202-4897-8421-a4aa2285d4b0/.default'],
  },
};
